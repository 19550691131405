"use strict";

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Button,
  CircularProgress,
  Stack,
  Toolbar,
  styled,
} from "@mui/material";
import { FC, ReactElement, useCallback } from "react";
import ProfileIconButton from "../ProfileIconButton";
import useLog from "../../../log/useLog";
import ThemeSwitch from "../../common/theme_switch/ThemeSwitch";
import { isMobile } from "react-device-detect";
import LayoutTopBarProblemUnitViewProgress from "./problem_unit_view_progress/LayoutTopBarProblemUnitViewProgress";
import useCategory from "../useCategory";
import useModuleType, { ModuleType } from "../useModuleType";
import useUser from "../../../user/useUser";

const LayoutTopBarMenuButton = React.lazy(
  () => import("./menu_button/LayoutTopBarMenuButton"),
);
const LayoutTopBarBreadcrumbs = React.lazy(
  () => import("./breadcrubs/LayoutTopBarBreadcrumbs"),
);

const LayoutTopBar: FC = (): ReactElement => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const log = useLog();
  const { isAdmin } = useUser();
  const category = useCategory();
  const moduleType = useModuleType();
  const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

  const onLoginButtonClick = useCallback(() => {
    loginWithRedirect();
    log({ viewType: null, eventType: "ON_LOGIN_ICON_BUTTON_CLICK" });
  }, [loginWithRedirect, log]);

  return (
    <>
      <AppBar>
        <Toolbar>
          <Stack direction="row" flexGrow={1} gap={1} alignItems="center">
            {isMobile || isAdmin ? (
              <LayoutTopBarMenuButton />
            ) : (
              <LayoutTopBarBreadcrumbs />
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={isAuthenticated ? 2 : 0}
          >
            {!isAuthenticated && <ThemeSwitch />}
            {isAuthenticated &&
              category != null &&
              moduleType === ModuleType.QUESTIONS && (
                <LayoutTopBarProblemUnitViewProgress category={category} />
              )}
            {isLoading ? (
              <CircularProgress />
            ) : isAuthenticated ? (
              <ProfileIconButton />
            ) : (
              <Button color="inherit" onClick={onLoginButtonClick}>
                Login
              </Button>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  );
};

export default LayoutTopBar;
