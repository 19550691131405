/**
 * @generated SignedSource<<6d26ee1d8dd42c756cb7e36ffb164954>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LogEventType = "ON_ANSWER_ACCORDION_CHANGE" | "ON_ANSWER_SUBMIT_BUTTON_CLICK" | "ON_BACK_TO_SEACH_ICON_BUTTON_CLICK" | "ON_COMMENT_BUTTON_CLIC" | "ON_DEPEND_PROBLEMS_ACCORDION_CHANGE" | "ON_DIFFICULTY_CHANGE" | "ON_FAVORITE_CHANGE" | "ON_FAVORITE_ICON_BUTTON_CLICK" | "ON_FOLLOW_UP_PROBLEMS_ACCORDION_CHANGE" | "ON_LOGIN_ICON_BUTTON_CLICK" | "ON_NEXT_PROBLEM_BUTTON_CLICK" | "ON_PREV_PROBLEM_BUTTON_CLICK" | "ON_PROBLEM_STATUS_CHANGE" | "ON_PROBLEM_TABLE_ROW_CLICK" | "ON_PROFILE_ICON_BUTTON_CLICK" | "ON_QUESTION_CHANGE" | "ON_RANDOM_BUTTON_CLICK" | "ON_RELATED_PROBLEMS_ACCORDION_PROBLEM_LIST_ITEM_BUTTON_CLICK" | "ON_SPEECH_TO_TEXT_BUTTON_CLICK" | "ON_STARTED_BUTTON_CLICK" | "ON_START_MODAL_SELECT_CHANGE" | "ON_START_MODAL_START_PRACTICING_BUTTON_CLICK" | "ON_TAGS_CHANGE" | "ON_TAG_CHIP_CLICK" | "ON_TRY_FOR_FREE_FAB_CLICK" | "ON_UNIT_VIEW_TAB_CHANGE" | "PAGE_LOAD" | "SPEECH_EXCEED_LIMIT_AND_STOPPED";
export type LogViewType = "LANDING_VIEW" | "SEARCH_VIEW" | "UNIT_VIEW";
export type useLogMutation$variables = {
  dateTime: string;
  eventType: LogEventType;
  jsonEncodedContext?: string | null | undefined;
  url: string;
  viewType?: LogViewType | null | undefined;
};
export type useLogMutation$data = {
  readonly log: string | null | undefined;
};
export type useLogMutation = {
  response: useLogMutation$data;
  variables: useLogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "eventType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jsonEncodedContext"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "url"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewType"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "date_time",
        "variableName": "dateTime"
      },
      {
        "kind": "Variable",
        "name": "event_type",
        "variableName": "eventType"
      },
      {
        "kind": "Variable",
        "name": "json_encoded_context",
        "variableName": "jsonEncodedContext"
      },
      {
        "kind": "Variable",
        "name": "url",
        "variableName": "url"
      },
      {
        "kind": "Variable",
        "name": "view_type",
        "variableName": "viewType"
      }
    ],
    "kind": "ScalarField",
    "name": "log",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useLogMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useLogMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "645e84d355e154d5ddbf286071e69d44",
    "id": null,
    "metadata": {},
    "name": "useLogMutation",
    "operationKind": "mutation",
    "text": "mutation useLogMutation(\n  $dateTime: String!\n  $viewType: LogViewType\n  $eventType: LogEventType!\n  $url: String!\n  $jsonEncodedContext: String\n) {\n  log(date_time: $dateTime, view_type: $viewType, event_type: $eventType, url: $url, json_encoded_context: $jsonEncodedContext)\n}\n"
  }
};
})();

(node as any).hash = "ae941810fceae3d4334399c63b59ea19";

export default node;
