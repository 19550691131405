/**
 * @generated SignedSource<<e62350ca1c9be7e977f2f1efa5aed92b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UserFeedbackSpeedDialActionCreateMutation$variables = {
  content?: string | null | undefined;
  recommend: boolean;
  score: number;
};
export type UserFeedbackSpeedDialActionCreateMutation$data = {
  readonly create__user_feedback: {
    readonly id: string;
  };
};
export type UserFeedbackSpeedDialActionCreateMutation = {
  response: UserFeedbackSpeedDialActionCreateMutation$data;
  variables: UserFeedbackSpeedDialActionCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "content"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recommend"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "score"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "content",
        "variableName": "content"
      },
      {
        "kind": "Variable",
        "name": "recommend",
        "variableName": "recommend"
      },
      {
        "kind": "Variable",
        "name": "score",
        "variableName": "score"
      }
    ],
    "concreteType": "UserFeedback",
    "kind": "LinkedField",
    "name": "create__user_feedback",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserFeedbackSpeedDialActionCreateMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserFeedbackSpeedDialActionCreateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "223b52e3f4dafc5f896ac2c04233dcef",
    "id": null,
    "metadata": {},
    "name": "UserFeedbackSpeedDialActionCreateMutation",
    "operationKind": "mutation",
    "text": "mutation UserFeedbackSpeedDialActionCreateMutation(\n  $score: Int!\n  $recommend: Boolean!\n  $content: String\n) {\n  create__user_feedback(score: $score, recommend: $recommend, content: $content) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f6c16b08511f8f76c80cf32ea6a5dd9";

export default node;
