"use strict";

import { FC, ReactElement, ReactNode, useMemo } from "react";
import { Modal, ModalProps, Stack, SxProps, Theme } from "@mui/material";
import useTheme from "../../../theme/useTheme";

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: 800,
    minHeight: 400,
    maxHeight: "90%",
    border: "2px solid black",
  },
};

interface Props extends Omit<ModalProps, "children"> {
  children: ReactNode;
  InnerProps?: { sx?: SxProps<Theme> };
}

const ScrollableModal: FC<Props> = ({
  children,
  InnerProps,
  ...props
}): ReactElement => {
  const { theme } = useTheme();

  const innerPropsSx = useMemo(() => {
    const { sx = [] } = InnerProps ?? {};
    return [
      styles.modal,
      { backgroundColor: theme.palette.background.paper },
      ...(Array.isArray(sx) ? sx : [sx]),
    ];
  }, [InnerProps, theme.palette.background.paper]);

  return (
    <Modal {...props}>
      <Stack sx={innerPropsSx} padding={3} gap={2} overflow="scroll">
        {children}
      </Stack>
    </Modal>
  );
};

export default ScrollableModal;
