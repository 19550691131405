"use strict";

import { FC, ReactElement, ReactNode } from "react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TypographyOwnProps,
} from "@mui/material";
import ProblemDifficultyChip from "../../../common/ProblemDifficultyChip";
import { ProblemDifficulty } from "../../../landing_view/__generated__/LandingViewInviniteAutoplayCarouselQuery.graphql";

export type DifficultyProblemStats = {
  difficulty: ProblemDifficulty;
  label: string;
  count: number;
  totalCount: number;
  color: TypographyOwnProps["color"];
};

const TableCellWithoutBorder: FC<{ children: ReactNode }> = ({ children }) => (
  <TableCell sx={{ borderBottom: "none" }}>{children}</TableCell>
);

interface Props {
  difficultyProblemStats: DifficultyProblemStats[];
}

const LayoutTopBarProblemUnitViewProgressTable: FC<Props> = ({
  difficultyProblemStats,
}): ReactElement => {
  return (
    <Table size="small">
      <TableBody>
        {difficultyProblemStats.map(
          ({ difficulty, count, totalCount, color }) => (
            <TableRow key={difficulty}>
              <TableCellWithoutBorder>
                <ProblemDifficultyChip difficulty={difficulty} size="small" />
              </TableCellWithoutBorder>
              <TableCellWithoutBorder>
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Typography color={color} fontWeight="bold">
                    {count}
                  </Typography>
                  <Typography noWrap={true}> / {totalCount}</Typography>
                </Stack>
              </TableCellWithoutBorder>
            </TableRow>
          ),
        )}
      </TableBody>
    </Table>
  );
};

export default LayoutTopBarProblemUnitViewProgressTable;
