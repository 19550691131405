/**
 * @generated SignedSource<<08e8aa1b8846994a05fa9421ca3cb7e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Category = "INVESTMENT_BANKING" | "TECH";
export type LayoutTopBarProblemUnitViewProgressRefetchQuery$variables = {
  category: Category;
  userID?: string | null | undefined;
};
export type LayoutTopBarProblemUnitViewProgressRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LayoutTopBarProblemUnitViewProgress_content">;
};
export type LayoutTopBarProblemUnitViewProgressRefetchQuery = {
  response: LayoutTopBarProblemUnitViewProgressRefetchQuery$data;
  variables: LayoutTopBarProblemUnitViewProgressRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "category"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userID"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "equals",
        "variableName": "category"
      }
    ],
    "kind": "ObjectValue",
    "name": "category"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutTopBarProblemUnitViewProgressRefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "category",
            "variableName": "category"
          },
          {
            "kind": "Variable",
            "name": "userID",
            "variableName": "userID"
          }
        ],
        "kind": "FragmentSpread",
        "name": "LayoutTopBarProblemUnitViewProgress_content"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LayoutTopBarProblemUnitViewProgressRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
          }
        ],
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "fetch__user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "fields": (v1/*: any*/),
                "kind": "ObjectValue",
                "name": "problem_where"
              },
              {
                "kind": "Literal",
                "name": "progress_status",
                "value": "COMPLETED"
              }
            ],
            "concreteType": "ProblemConnection",
            "kind": "LinkedField",
            "name": "problem_progress_status_problems",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": (v1/*: any*/),
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "ProblemConnection",
        "kind": "LinkedField",
        "name": "multifetch__problem",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "86a80af496b1865f5470fa39cb7caf4f",
    "id": null,
    "metadata": {},
    "name": "LayoutTopBarProblemUnitViewProgressRefetchQuery",
    "operationKind": "query",
    "text": "query LayoutTopBarProblemUnitViewProgressRefetchQuery(\n  $category: Category!\n  $userID: ID\n) {\n  ...LayoutTopBarProblemUnitViewProgress_content_9dFED\n}\n\nfragment LayoutTopBarProblemUnitViewProgress_content_9dFED on Query {\n  fetch__user(id: $userID) {\n    problem_progress_status_problems(progress_status: COMPLETED, problem_where: {category: {equals: $category}}) {\n      count\n    }\n    id\n  }\n  multifetch__problem(where: {category: {equals: $category}}) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "49e5844bf11430a877af1d80bc81f2a0";

export default node;
