"use strict";

import { FC, ReactElement, useState } from "react";
import {
  Link,
  SpeedDialAction,
  SpeedDialActionProps,
  Stack,
  Typography,
} from "@mui/material";
import {
  OpenInNew as OpenInNewIcon,
  Recommend as RecommendIcon,
} from "@mui/icons-material";
import ScrollableModal from "../../common/modal/ScrollableModal";

const styles = {
  venmoImg: {
    width: 233.5,
    height: 300,
    alignSelf: "center",
  },
};

const DonationSpeedDialAction: FC<SpeedDialActionProps> = (
  props,
): ReactElement => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SpeedDialAction
        icon={<RecommendIcon />}
        tooltipTitle="Support us"
        onClick={() => setOpen(true)}
        {...props}
      />
      <ScrollableModal open={open} onClose={() => setOpen(false)}>
        <Typography variant="h5">InterviewLab need your support!</Typography>
        <img src="/images/venmo.jpg" style={styles.venmoImg} />
        <Link
          href="https://venmo.com/u/Shuo-Yang-Wang"
          target="_blank"
          fontSize={20}
        >
          <Stack direction="row" alignItems="center" gap={0.5}>
            Support us{" "}
            <OpenInNewIcon fontSize="small" alignmentBaseline="middle" />
          </Stack>
        </Link>
        InterviewLab is a free service dedicated to helping people land their
        dream jobs. We've invested our own funds and countless hours to ensure
        InterviewLab remains top-notch. To keep this service running, we're
        seeking donations from our supporters. If you value what we offer,
        please consider making a contribution. Your support will help us
        maintain and improve InterviewLab, ensuring we continue providing
        high-quality content. Every little bit helps!
      </ScrollableModal>
    </>
  );
};

export default DonationSpeedDialAction;
