"use strict";

import { FC, ReactElement } from "react";
import { Chip, SxProps, Theme } from "@mui/material";
import { ProblemDifficulty } from "../unit_view/common/__generated__/ProblemUnitViewQuery.graphql";

interface Props {
  difficulty: ProblemDifficulty;
  size?: "medium" | "small";
  sx?: SxProps<Theme>;
}

const ProblemDifficultyChip: FC<Props> = ({
  difficulty,
  ...props
}: Props): ReactElement => {
  switch (difficulty) {
    case "EASY":
      return <Chip label="Easy" color="success" {...props} />;
    case "MEDIUM":
      return <Chip label="Medium" color="warning" {...props} />;
    case "HARD":
      return <Chip label="Hard" color="error" {...props} />;
    default:
      throw new Error(`Unsupport ProblemDifficulty ${difficulty}`);
  }
};

export default ProblemDifficultyChip;
