/**
 * @generated SignedSource<<78a86861fbe8a4e994c9a219fcb27518>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type Role = "EDITOR" | "USER";
export type useUserUpdateResumeMutation$variables = {
  file?: any | null | undefined;
};
export type useUserUpdateResumeMutation$data = {
  readonly update__user_resume: {
    readonly display_id: string | null | undefined;
    readonly id: string;
    readonly name: string | null | undefined;
    readonly picture_url: string | null | undefined;
    readonly resume_url: string | null | undefined;
    readonly role: Role;
  } | null | undefined;
};
export type useUserUpdateResumeMutation = {
  response: useUserUpdateResumeMutation$data;
  variables: useUserUpdateResumeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "file"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "resume_file",
        "variableName": "file"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "update__user_resume",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "display_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "picture_url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resume_url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUserUpdateResumeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUserUpdateResumeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "45b7140a152ab34a79412793fef8e6ce",
    "id": null,
    "metadata": {},
    "name": "useUserUpdateResumeMutation",
    "operationKind": "mutation",
    "text": "mutation useUserUpdateResumeMutation(\n  $file: Upload\n) {\n  update__user_resume(resume_file: $file) {\n    id\n    name\n    display_id\n    picture_url\n    resume_url\n    role\n  }\n}\n"
  }
};
})();

(node as any).hash = "cb329b70bbc276c367d2e497895d1fed";

export default node;
