/**
 * @generated SignedSource<<b2670e655c58754e0819122db9013fef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LayoutTopBarProblemUnitViewProgress_content$data = {
  readonly fetch__user: {
    readonly problem_progress_status_problems: {
      readonly count: number;
    } | null | undefined;
  } | null | undefined;
  readonly multifetch__problem: {
    readonly count: number;
  };
  readonly " $fragmentType": "LayoutTopBarProblemUnitViewProgress_content";
};
export type LayoutTopBarProblemUnitViewProgress_content$key = {
  readonly " $data"?: LayoutTopBarProblemUnitViewProgress_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"LayoutTopBarProblemUnitViewProgress_content">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "equals",
        "variableName": "category"
      }
    ],
    "kind": "ObjectValue",
    "name": "category"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "category"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userID"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./LayoutTopBarProblemUnitViewProgressRefetchQuery.graphql')
    }
  },
  "name": "LayoutTopBarProblemUnitViewProgress_content",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "userID"
        }
      ],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "fetch__user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "fields": (v0/*: any*/),
              "kind": "ObjectValue",
              "name": "problem_where"
            },
            {
              "kind": "Literal",
              "name": "progress_status",
              "value": "COMPLETED"
            }
          ],
          "concreteType": "ProblemConnection",
          "kind": "LinkedField",
          "name": "problem_progress_status_problems",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": (v0/*: any*/),
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "ProblemConnection",
      "kind": "LinkedField",
      "name": "multifetch__problem",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "49e5844bf11430a877af1d80bc81f2a0";

export default node;
