"use strict";

import { useLocation } from "react-router-dom";
import { getCategoryFromPathname } from "../../route/RouteUtils";
import { useMemo } from "react";
import { Category } from "../common/__generated__/ProblemLikeIconButtonCreateFavoriteMutation.graphql";
import {
  AccountBalance as AccountBalanceIcon,
  Build as BuildIcon,
  SvgIconComponent,
} from "@mui/icons-material";
import { ChipOwnProps } from "@mui/material";

export const CATEGORY_METADATA: {
  [key in Category]: {
    path: string;
    title: string;
    Icon: SvgIconComponent;
    chipColor: ChipOwnProps["color"];
  };
} = {
  INVESTMENT_BANKING: {
    path: "investment_banking",
    title: "Investment Banking",
    Icon: AccountBalanceIcon,
    chipColor: "info",
  },
  TECH: {
    path: "product_management",
    title: "Product Management",
    Icon: BuildIcon,
    chipColor: "secondary",
  },
};

export default function useCategory(): Category | null {
  const { pathname } = useLocation();

  return useMemo(() => getCategoryFromPathname(pathname), [pathname]);
}
