"use strict";

import { graphql } from "babel-plugin-relay/macro";
import { FC, ReactElement, useCallback, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SpeedDialAction,
  SpeedDialActionProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Feedback as FeedbackIcon } from "@mui/icons-material";
import { useMutation } from "react-relay";
import { LoadingButton } from "@mui/lab";
import invariant from "tiny-invariant";
import { UserFeedbackSpeedDialActionCreateMutation } from "./__generated__/UserFeedbackSpeedDialActionCreateMutation.graphql";
import SatisfactionRating, {
  Props as SatisfactionRatingProps,
} from "../../unit_view/common/satisfaction_rating/SatisfactionRating";
import ScrollableModal from "../../common/modal/ScrollableModal";
import CopyToClipboardButton from "../../common/CopyToClipboardButton";

const CONTENT_CHARACTER_LIMIT = 1000;
const styles = {
  textField: {
    flexGrow: 1,
  },
  textFieldInput: {
    borderRadius: 0,
  },
  clipboardButton: {
    borderRadius: 0,
  },
  copySharableLinkModalInner: {
    minHeight: 0,
  },
};

const UserFeedbackSpeedDialActionLinkCopyField: FC = (): ReactElement => {
  const { href } = location;

  return (
    <Stack direction="row" width="100%">
      <TextField
        variant="filled"
        disabled={true}
        defaultValue={href}
        size="small"
        hiddenLabel={true}
        sx={styles.textField}
        InputProps={{ sx: styles.textFieldInput }}
      />
      <CopyToClipboardButton content={href} sx={styles.clipboardButton} />
    </Stack>
  );
};

const UserFeedbackSpeedDialAction: FC<SpeedDialActionProps> = (
  props,
): ReactElement => {
  const [open, setOpen] = useState(false);
  const [shareLinkOpen, setShareLinkOpen] = useState(false);
  const [score, setScore] = useState<number | null>(null);
  const [recommend, setRecommend] = useState<boolean | null>(null);
  const [content, setContent] = useState<string | null>(null);
  const [commit, isInFlight] =
    useMutation<UserFeedbackSpeedDialActionCreateMutation>(graphql`
      mutation UserFeedbackSpeedDialActionCreateMutation(
        $score: Int!
        $recommend: Boolean!
        $content: String
      ) {
        create__user_feedback(
          score: $score
          recommend: $recommend
          content: $content
        ) {
          id
        }
      }
    `);

  const onClick = useCallback(() => {
    invariant(score != null, "score shouldn't be null");
    invariant(recommend != null, "recommend shouldn't be null");
    commit({
      variables: { score, recommend, content },
      onCompleted: () => {
        setOpen(false);
        if (recommend === true) {
          setShareLinkOpen(true);
        }
        setScore(null);
        setRecommend(null);
        setContent(null);
      },
    });
  }, [commit, score, recommend, content]);

  const onRatingChange: SatisfactionRatingProps["onChange"] = useCallback(
    (_event, rating) => {
      setScore(rating);
    },
    [],
  );

  const characterCount = useMemo(() => (content ?? "").length, [content]);

  return (
    <>
      <SpeedDialAction
        icon={<FeedbackIcon />}
        tooltipTitle="Feedback"
        onClick={() => setOpen(true)}
        {...props}
      />
      <ScrollableModal open={open} onClose={() => setOpen(false)}>
        <Typography variant="h5">Feedback</Typography>
        <FormControl required={true}>
          <FormLabel sx={{ marginBottom: 1 }}>
            Are you satisfy with InterviewLab?
          </FormLabel>
          <SatisfactionRating value={score} onChange={onRatingChange} />
        </FormControl>
        <FormControl required={true}>
          <FormLabel focused={false}>
            Will you recommend InterviewLab to your friends?
          </FormLabel>
          <RadioGroup
            row={true}
            value={recommend}
            onChange={(_event, value) => setRecommend(value == "true")}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel sx={{ marginBottom: 1 }}>
            Do you have any feedback? (Optional)
          </FormLabel>
          <TextField
            value={content ?? ""}
            onChange={(event) => setContent(event.target.value)}
            variant="outlined"
            multiline={true}
            rows={5}
            fullWidth={true}
            size="small"
            error={characterCount > CONTENT_CHARACTER_LIMIT}
            helperText={`${characterCount}/${CONTENT_CHARACTER_LIMIT} characters`}
          />
        </FormControl>
        <Stack direction="row" justifyContent="flex-end">
          <Box>
            <LoadingButton
              variant="contained"
              loading={isInFlight}
              disabled={score == null || recommend == null}
              onClick={onClick}
            >
              Submit
            </LoadingButton>
          </Box>
        </Stack>
      </ScrollableModal>
      <ScrollableModal
        open={shareLinkOpen}
        onClose={() => setShareLinkOpen(false)}
        InnerProps={{ sx: styles.copySharableLinkModalInner }}
      >
        <Typography variant="h5">Sharing is Caring!</Typography>
        <Typography display="flex">
          Copy a sharable link and share with friends.
        </Typography>
        <UserFeedbackSpeedDialActionLinkCopyField />
      </ScrollableModal>
    </>
  );
};

export default UserFeedbackSpeedDialAction;
