"use strict";

import {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLazyLoadQuery, useRefetchableFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  Button,
  Grid2,
  Skeleton,
  Stack,
  Typography,
  TypographyOwnProps,
} from "@mui/material";
import Suspensable from "../../../common/Suspensable";
import useUser from "../../../../user/useUser";
import LayoutContext from "../../LayoutContext";
import ScrollableModal from "../../../common/modal/ScrollableModal";
import TooltipWithStopPropogation from "../../../common/TooltipWithStopPropogation";
import {
  Category,
  ProblemDifficulty,
} from "../../../landing_view/__generated__/LandingViewInviniteAutoplayCarouselQuery.graphql";
import useTheme, { ThemeType } from "../../../../theme/useTheme";
import LayoutTopBarProblemUnitViewProgressPieChart from "./LayoutTopBarProblemUnitViewProgressPieChart";
import { LayoutTopBarProblemUnitViewProgress_content$key } from "./__generated__/LayoutTopBarProblemUnitViewProgress_content.graphql";
import { LayoutTopBarProblemUnitViewProgressModalContentQuery } from "./__generated__/LayoutTopBarProblemUnitViewProgressModalContentQuery.graphql";
import { LayoutTopBarProblemUnitViewProgressQuery } from "./__generated__/LayoutTopBarProblemUnitViewProgressQuery.graphql";
import { LayoutTopBarProblemUnitViewProgressRefetchQuery } from "./__generated__/LayoutTopBarProblemUnitViewProgressRefetchQuery.graphql";
import LayoutTopBarProblemUnitViewProgressTable from "./LayoutTopBarProblemUnitViewProgressTable";

export type DifficultyProblemStats = {
  difficulty: ProblemDifficulty;
  label: string;
  count: number;
  totalCount: number;
  color: TypographyOwnProps["color"];
};

const LayoutTopBarProblemUnitViewProgressModalContent: FC<Props> = ({
  category,
}): ReactElement => {
  const { user } = useUser();
  const { theme } = useTheme();
  const { easy, medium, hard, totalEasy, totalMedium, totalHard } =
    useLazyLoadQuery<LayoutTopBarProblemUnitViewProgressModalContentQuery>(
      graphql`
        query LayoutTopBarProblemUnitViewProgressModalContentQuery(
          $userID: ID
          $category: Category!
        ) {
          easy: fetch__user(id: $userID) {
            problem_progress_status_problems(
              progress_status: COMPLETED
              problem_where: {
                category: { equals: $category }
                difficulty: { equals: EASY }
              }
            ) {
              count
            }
          }
          medium: fetch__user(id: $userID) {
            problem_progress_status_problems(
              progress_status: COMPLETED
              problem_where: {
                category: { equals: $category }
                difficulty: { equals: MEDIUM }
              }
            ) {
              count
            }
          }
          hard: fetch__user(id: $userID) {
            problem_progress_status_problems(
              progress_status: COMPLETED
              problem_where: {
                category: { equals: $category }
                difficulty: { equals: HARD }
              }
            ) {
              count
            }
          }
          totalEasy: multifetch__problem(
            where: {
              category: { equals: $category }
              difficulty: { equals: EASY }
            }
          ) {
            count
          }
          totalMedium: multifetch__problem(
            where: {
              category: { equals: $category }
              difficulty: { equals: MEDIUM }
            }
          ) {
            count
          }
          totalHard: multifetch__problem(
            where: {
              category: { equals: $category }
              difficulty: { equals: HARD }
            }
          ) {
            count
          }
        }
      `,
      {
        userID: user?.id,
        category,
      },
      { fetchPolicy: "network-only" },
    );
  const { count: easyCount = 0 } = easy?.problem_progress_status_problems ?? {};
  const { count: mediumCount = 0 } =
    medium?.problem_progress_status_problems ?? {};
  const { count: hardCount = 0 } = hard?.problem_progress_status_problems ?? {};
  const { count: totalEasyCount = 0 } = totalEasy ?? {};
  const { count: totalMediumCount = 0 } = totalMedium ?? {};
  const { count: totalHardCount = 0 } = totalHard ?? {};

  const difficultyProblemStats: DifficultyProblemStats[] = useMemo(
    () => [
      {
        difficulty: "EASY",
        label: "Easy",
        count: easyCount,
        totalCount: totalEasyCount,
        color: theme.palette.success.main,
      },
      {
        difficulty: "MEDIUM",
        label: "Medium",
        count: mediumCount,
        totalCount: totalMediumCount,
        color: theme.palette.warning.main,
      },
      {
        difficulty: "HARD",
        label: "Hard",
        count: hardCount,
        totalCount: totalHardCount,
        color: theme.palette.error.main,
      },
    ],
    [
      easyCount,
      hardCount,
      mediumCount,
      theme.palette.error.main,
      theme.palette.success.main,
      theme.palette.warning.main,
      totalEasyCount,
      totalHardCount,
      totalMediumCount,
    ],
  );

  return (
    <Grid2 container={true}>
      <Grid2 size={7}>
        <LayoutTopBarProblemUnitViewProgressPieChart
          difficultyProblemStats={difficultyProblemStats}
        />
      </Grid2>
      <Grid2 size={5}>
        <LayoutTopBarProblemUnitViewProgressTable
          difficultyProblemStats={difficultyProblemStats}
        />
      </Grid2>
    </Grid2>
  );
};

const LayoutTopBarProblemUnitViewProgressContent: FC<Props> = ({
  category,
}): ReactElement => {
  const [open, setOpen] = useState(false);
  const { shouldTopBarProgressUpdate, setShouldTopBarProgressUpdate } =
    useContext(LayoutContext);
  const { user } = useUser();
  const { themeType } = useTheme();
  const dataFragment =
    useLazyLoadQuery<LayoutTopBarProblemUnitViewProgressQuery>(
      graphql`
        query LayoutTopBarProblemUnitViewProgressQuery(
          $userID: ID
          $category: Category!
        ) {
          ...LayoutTopBarProblemUnitViewProgress_content
            @arguments(userID: $userID, category: $category)
        }
      `,
      { userID: user?.id, category },
    );

  const [data, refetch] = useRefetchableFragment<
    LayoutTopBarProblemUnitViewProgressRefetchQuery,
    LayoutTopBarProblemUnitViewProgress_content$key
  >(
    graphql`
      fragment LayoutTopBarProblemUnitViewProgress_content on Query
      @refetchable(queryName: "LayoutTopBarProblemUnitViewProgressRefetchQuery")
      @argumentDefinitions(
        userID: { type: "ID" }
        category: { type: "Category!" }
      ) {
        fetch__user(id: $userID) {
          problem_progress_status_problems(
            progress_status: COMPLETED
            problem_where: { category: { equals: $category } }
          ) {
            count
          }
        }
        multifetch__problem(where: { category: { equals: $category } }) {
          count
        }
      }
    `,
    dataFragment,
  );

  useEffect(() => {
    if (shouldTopBarProgressUpdate) {
      refetch({ userID: user?.id, category }, { fetchPolicy: "network-only" });
      setShouldTopBarProgressUpdate(false);
    }
  }, [
    category,
    refetch,
    setShouldTopBarProgressUpdate,
    shouldTopBarProgressUpdate,
    user?.id,
  ]);

  const {
    fetch__user,
    multifetch__problem: { count: totalProblemCount },
  } = data;

  return (
    <>
      <TooltipWithStopPropogation title="See more stats">
        <Button
          variant={themeType === ThemeType.LIGHT ? "contained" : "text"}
          onClick={() => setOpen(true)}
          sx={{
            padding: 0,
            boxShadow: "none",
            "&:hover": { boxShadow: "none" },
          }}
        >
          <Stack alignItems="center">
            <Stack direction="row" gap={1}>
              <Typography color="white" fontWeight="bold">
                {fetch__user?.problem_progress_status_problems?.count ?? 0}
              </Typography>
              <Typography color="textDisabled">/</Typography>
              <Typography color="textDisabled">{totalProblemCount}</Typography>
            </Stack>
            <Typography variant="subtitle2" color="textDisabled">
              Total
            </Typography>
          </Stack>
        </Button>
      </TooltipWithStopPropogation>
      {open && (
        <ScrollableModal
          open={true}
          onClose={() => setOpen(false)}
          InnerProps={{ sx: { width: 500, minHeight: "fit-content" } }}
        >
          <Suspensable>
            <LayoutTopBarProblemUnitViewProgressModalContent
              category={category}
            />
          </Suspensable>
        </ScrollableModal>
      )}
    </>
  );
};

interface Props {
  category: Category;
}

const LayoutTopBarProblemUnitViewProgress: FC<Props> = (
  props,
): ReactElement => {
  return (
    <Suspensable
      fallback={
        <Stack alignItems="center">
          <Stack direction="row" gap={1}>
            <Skeleton variant="text" width={40} />
            <Typography color="textDisabled">/</Typography>
            <Skeleton variant="text" width={40} />
          </Stack>
          <Typography variant="subtitle2" color="textDisabled">
            Total
          </Typography>
        </Stack>
      }
    >
      <LayoutTopBarProblemUnitViewProgressContent {...props} />
    </Suspensable>
  );
};

export default LayoutTopBarProblemUnitViewProgress;
